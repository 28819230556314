<template>
  <div class="iframe-container">
    <meta charset="utf-8" />
    <link
      type="text/css"
      rel="stylesheet"
      href="https://source.zoom.us/2.9.5/css/bootstrap.css"
    />
    <link
      type="text/css"
      rel="stylesheet"
      href="https://source.zoom.us/2.9.5/css/react-select.css"
    />
    <meta name="format-detection" content="telephone=no" />
  </div>
</template>

<script>
import { ZoomMtg } from "@zoomus/websdk";
import MeetingService from "@/services/MeetingService";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.9.5/lib", "/av");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");
// ZoomMtg.prepareWebSDK();
export default {
  name: "ZoomFrame",
  data() {
    return {
      meetingNumber: "",
      password: "",
    };
  },
  props: {
    scheduleId: String,
  },
  computed: {
    user() {
      let user = this.$store.state.auth.user;
      if (user.length == undefined) {
        user = {};
      } else {
        user = JSON.parse(user);
        if (user.first_name !== null) {
          if (user.last_name !== null) {
            user.fullName = user.first_name + " " + user.last_name;
          } else {
            user.fullName = user.first_name;
          }
        } else {
          user.fullName = null;
        }
      }
      return user;
    },
  },
  created: function () {
    this.fetchMeetingDetails();
  },
  methods: {
    randomString(length = 8) {
      return Math.random().toString(16).substr(2, length);
    },
    fetchMeetingDetails() {
      let params = {
        schedule_id: this.scheduleId,
      };
      MeetingService.getMeetingDetails(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.meetingNumber = response.data.account.meeting_id;
            this.password = response.data.account.password;
            this.startMeeting();
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
            this.$router.push({
              name: "MyCourses",
              query: { from: "zoom-error" },
            });
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    startMeeting() {
      var API_KEY = "nMUvRkAYLnJDS8mxiw73ilSXcTftveNTQ2kc";
      var API_SECRET = "QOlSUumVytx7lJRNigsd0Z5fVrIM6VOAifgy";
      let randomString = this.randomString(); // default by 8 characters
      if (this.user.fullName === null) {
        this.user.fullName = randomString;
      }
      if (this.user.email === null) {
        this.user.email = randomString + ".student@vblive.com";
      }
      // console.log(this.user);
      const meetConfig = {
        sdkKey: API_KEY,
        meetingNumber: this.meetingNumber,
        leaveUrl:
          process.env.BASE_URL +
          "dashboard/my-courses?from=zoom-success&schedule=" +
          this.scheduleId,
        userName: this.user.fullName,
        userEmail: this.user.email,
        passWord: this.password, // if required
        role: 0, // 1 for host; 0 for attendee
      };
      var signature = ZoomMtg.generateSDKSignature({
        meetingNumber: meetConfig.meetingNumber,
        sdkKey: API_KEY,
        sdkSecret: API_SECRET,
        role: meetConfig.role,
        success: function (res) {
          console.log(res.result);
          meetConfig.signature = res.result;
          meetConfig.sdkKey = API_KEY;
        },
      });

      this.joinMeeting(meetConfig, signature);
    },

    //   this.generateSignature(
    //     meetConfig.meetingNumber,
    //     meetConfig.role,
    //     meetConfig
    //   );
    // },
    // async generateSignature(meetingNumber, role, meetConfig) {
    //   var signature = await MeetingService.generateSignature({
    //     meeting_number: meetingNumber,
    //     role: role,
    //   });
    //   this.joinMeeting(meetConfig, signature.data);
    // },
    joinMeeting(meetConfig, signature) {
      ZoomMtg.init({
        leaveUrl: meetConfig.leaveUrl,
        isSupportAV: true,
        meetingInfo: ["topic", "host"],
        success: function () {
          ZoomMtg.join({
            meetingNumber: meetConfig.meetingNumber,
            passWord: meetConfig.passWord,
            sdkKey : meetConfig.sdkKey,
            userName: meetConfig.userName,
            userEmail: meetConfig.userEmail,
            signature: signature,
            error(res) {
              console.log(res);
            },
          });
        },
      });
    },
    // changeJoinText() {
    //   $(".join-meeting").text("Joining Class..");
    // },
  },
  // mounted: function () {
  //   setInterval(this.changeJoinText, 1000);
  // },
};
</script>
