import Http from "@/services/Http";

export default {
  getMeetingDetails(data) {
    return Http().post("meeting/details/get", data);
  },
  exitMeeting(data) {
    return Http().post("meeting/exit", data);
  },
  generateSignature(data) {
    return Http().post("signature/generate", data);
  },
};
